import axios from 'axios'
import { message } from 'antd';
import Url from './config';
const http = axios.create({
    baseURL:Url,
    // timeout:15000, // 请求超时
})
// 请求拦截器
http.interceptors.request.use(config=>{
    let token = JSON.parse(sessionStorage.getItem('userInfo'))?JSON.parse(sessionStorage.getItem('userInfo')).token:'';
    //判断有没有token
        if (!!token) {
            config.headers['Authorization'] ='Bearer '+ token // 让每个请求携带自定义token 请根据实际情况自行修改
        }

    return config;
},error => {
    Promise.reject(error);
})
// 响应拦截器
http.interceptors.response.use(response=>{
    // console.log(response,"message")
    if(response.status!==200){
        message.error('请求出错啦！请重试')
    }else {
        return response.data
    }
},error => {
    message.error('请求参数或者文件格式出错啦！请联系管理员',3);
    return Promise.reject(error)
});
export default http;