import axios from '../assets/axios'
/*
* #用户列表
---
请求地址 http://139.159.219.174/api/users
* **/
export function users(data) {
    return axios({
        method:'get',
        url:'/users',
        data
    })
}
/*
# 删除用户
---
请求地址 http://139.159.219.174/api/users/${id}
请求方法 DELETE
* **/
export function samples(id) {
    return axios({
        method:'delete',
        url:'/users/'+id,
    })
}
/*
# 注册用户
---
请求地址 http://139.159.219.174/api/users/register
请求方法 post
* **/
export function register(data) {
    return axios({
        method:'post',
        url:'/users/register',
        data
    })
}
/*
# 编辑用户
---
请求地址http://139.159.219.174/api/users/${username}
请求方法 put
* **/
export function userUsername(data) {
    return axios({
        method:'put',
        url:'/users/'+data.username,
        data
    })
}