import React, { Component } from 'react';
import { Form, Icon, Input, Button } from 'antd'
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import {connect} from 'react-redux';
import {login,loginCreater} from '../../store/actions'
import {authenticate} from '../../api/login'
import './login.less'
class Login extends Component {
    render() {
        const layout = {
            labelCol: {
                span: 4,
            },
            wrapperCol: {
                span: 20,
            },
        };
        const tailLayout = {
            wrapperCol: {
                offset: 4,
                span: 20,
            },
        };

            return (
                <div className="login">
                     <div className="login_box">
                         <div className="logon">
                             <img src={require('./logo.png')} alt=""/>
                         </div>
                         <h4>卓云海智神经系统单基因遗传病云端分析平台</h4>
                         <Form
                             {...layout}
                             name="basic"
                             initialValues={{
                                 remember: true,
                             }}
                              onFinish={(e)=>{
                                  let obj = {
                                      "username": e.name, //用户名
                                      "password": e.password //密码
                                  }
                                  authenticate(obj).then(res=>{
                                      sessionStorage.setItem('userInfo',JSON.stringify(res));
                                      this.props.history.push('/home')

                                  })
                              }}
                              onFinishFailed={this.onFinishFailed.bind(this)}
                         >
                             <Form.Item
                                 label="用户名"
                                 name="name"
                                 rules={[
                                     {
                                         required: true,
                                         message: '用户名不能为空',
                                     },
                                 ]}
                             >
                                 <Input style={{width:'400px'}} placeholder='请输入用户名'/>
                             </Form.Item>

                             <Form.Item
                                 label="密码"
                                 name="password"
                                 rules={[
                                     {
                                         required: true,
                                         message: '密码不能为空',
                                     },
                                 ]}
                             >
                                 <Input.Password style={{width:'400px'}} placeholder='请输入密码'/>
                             </Form.Item>

                             <Form.Item {...tailLayout}>
                                 <Button type="primary" htmlType="submit">
                                     登录
                                 </Button>
                             </Form.Item>
                         </Form>
                     </div>
                    <p className="ipc">
                         <a href="https://beian.miit.gov.cn/" target='_blank'>粤ICP备2020098807号</a>版权所有：卓云海智网络科技（深圳）有限公司
                    </p>
                </div>

            )
        }
    onFinishFailed(e){
        console.log(e,"onFinishFailed")
    }
}

export default connect(
    state => ({
        userInfo:state.login,
    }),
        {loginCreater,login}
)(Login);
