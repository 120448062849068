import axios from '../assets/axios'
/*
* #分析列表
---
请求地址 http://139.159.219.174/api/samples
* **/
export function analyses(params) {
    return axios({
        method:'get',
        url:'/analyses',
        params
    })
}
/*
* #分析列表删除
---
请求地址 http://139.159.219.174/api/samples
* **/
export function delAnalysis(id) {
    return axios({
        method:'delete',
        url:'/analyses/'+id,
    })
}
/*
* #分析开始
---
请求地址 http://139.159.219.174/api/analyses/run
* **/
export function runAnalysis(data) {
    return axios({
        method:'post',
        url:'/analyses/run',
        data
    })
}
/*
* #分析添加
---
请求地址 http://139.159.219.174/api/analyses/create
* **/
export function createAnalysis(data) {
    return axios({
        method:'post',
        url:'/analyses/create',
        data
    })
}
/*
* #质量控制  target[0][0]
---
请求地址  http://139.159.219.174/api/snps/rs144938364_A
* **/
export function snps(id) {
    return axios({
        method:'get',
        url:'/snps/'+id,
    })
}

/*
* #样品报告
--- Manual_ID
请求地址  http://139.159.219.174/api/disease/379
* **/
export function disease(id) {
    return axios({
        method:'get',
        url:'/disease/'+id,
    })
}

/*
* #样品报告
--- Manual_ID
请求地址  http://139.159.219.174/api/reports/download/?id=1594722183404
* **/
export function reportsdownload(id) {
    return axios({
        method:'post',
        responseType: 'blob',
        url:'/reports/download/id='+id,
    })
}