import React, { useState,useEffect } from 'react';
import {Input,Card,Row,Col,Table,Button,Drawer  } from 'antd';
import {diseaseSearch} from '../../api/seach'
import './seach.less'
const { Column } = Table;
const { Search } = Input;
export default function Seach(){
    const [lists,setLists] = useState([]);
    const [contents,setContents] = useState([]);
    const [loading,setLoading] = useState(false);
    const [visible,visibleing] = useState(false);
    return (
        <div>
            <Card>
                <span>疾病搜索</span>
                <Search
                    allowClear={true}
                    placeholder="请输入需要搜索的疾病"
                    loading={loading}
                    onSearch={value =>{
                        let val= value.replace(/\s+/g,"");
                        if(!!val){
                            setLoading(true)
                            diseaseSearch(val).then(res=>{
                                let regExp = new RegExp(val, 'ig')
                                // let regExp = /`${val}`/ig;
                                res.forEach((item,index)=>{
                                    item.key = item.id
                                    item.Gene = item.Gene.replace(regExp,`<span style='color:#40a9ff'>${value}</span>`)
                                    item.DiseaseIntro = item.DiseaseIntro.replace(regExp,`<span style='color:#40a9ff'>${value}</span>`)
                                    item.Disease = item.Disease.replace(regExp,`<span style='color:#40a9ff'>${value}</span>`)
                                    item.Disease_ch = item.Disease_ch.replace(regExp,`<span style='color:#40a9ff'>${value}</span>`)
                                });
                                setLoading(false)
                                setLists(res);
                                setContents([]);
                            })
                        }else{
                            setLists([]);
                            setContents([]);
                        }
                    }}
                    style={{ width: 420,marginLeft:'5px' }}
                />
            </Card>
            {/**/}
            <Card>
                {
                    lists.length>0? <div className='box'>
                        <div className='ul'>
                            <Table
                                pagination={{pageSize:10}}
                                dataSource={lists}
                                onRow={record => {
                                    return {
                                        onClick: (event) => {
                                            let arr=[];
                                            for(let k in record){
                                                arr.push([k,record[k]])
                                            }
                                            setContents(arr);
                                            visibleing(true);

                                        }, // 点击行
                                    };
                                }}
                            >
                                <Column title="疾病编号" dataIndex="Manual_ID" key='Manual_ID' align="center" render={(text,row)=>(
                                    <div key={text} style={{color:'#40a9ff', cursor: 'pointer'}}>{text}</div>
                                )}/>
                                <Column title="表型编号" dataIndex="Phenotypic_ID" key='Phenotypic_ID' align="center" render={(text,row)=>(
                                    <div key={text} dangerouslySetInnerHTML={{__html:text}}></div>
                                )}/>
                                <Column width={400} title="疾病英文名" dataIndex="Disease" key='Disease' align="center" render={(text,row)=>(
                                    <div key={text} dangerouslySetInnerHTML={{__html:text}}></div>
                                )}/>
                                <Column width={400} title="疾病中文名" dataIndex="Disease_ch" key='Disease_ch' align="center" render={(text,row)=>(
                                    <div key={text} dangerouslySetInnerHTML={{__html:text}}></div>
                                )}/>
                            </Table>
                        </div>

                    </div>:<div className='noData'>
                         暂无搜索数据
                    </div>
                }


            </Card>
            <Drawer
                width={"60%"}
                title={'疾病详情'}
                placement="right"
                onClose={()=>{
                    visibleing(false);
                }}
                visible={visible}
                getContainer={false}
                style={{ position: 'absolute' }}
            >
                <div className='content'>
                    {
                        contents&&contents.map((item1,index)=> {
                            return (
                                item1[0]!='id'&&item1[0]!='_id'&&item1[0]!='key'&&<div key={item1[0]}>
                                    <Row style={{border: '1px solid #ccc', borderBottom: 0, textAlign: 'center'}}>
                                        <Col span={24} style={{fontSize:'16px',fontWeight:900,background:'#cef0fa'}}>
                                            {item1[0]==='Manual_ID'?'疾病编号':
                                                item1[0]==='Phenotypic_ID'?'表型编号':
                                                    item1[0]==='Disease'?'疾病英文名':
                                                        item1[0]==='Disease_ch'?'疾病中文名':
                                                            item1[0]==='Gene'?'基因':
                                                                item1[0]==='DiseaseIntro'?'疾病概述':
                                                                    item1[0]==='Clinic'?'临床表现':
                                                                        item1[0]==='image'?'影像学表现':
                                                                            item1[0]==='pathlog'?'致病机理':
                                                                                item1[0]==='GeneIntro'?'致病基因概述':
                                                                                    item1[0]==='geneFunc'?'致病基因功能':
                                                                                        item1[0]==='mechanism'?'基因突变致病机理':
                                                                                            item1[0]==='mutations'?'基因突变综述':
                                                                                                item1[0]==='reference'?'参考文献':item1[0]
                                            }
                                        </Col>
                                    </Row>
                                    <Row style={{border: '1px solid #ccc', borderBottom: 0}}>
                                        <Col style={{padding:'10px'}} span={24} dangerouslySetInnerHTML={{__html:item1[1]}}>
                                        </Col>
                                    </Row>
                                </div>

                            )
                        })
                    }
                </div>
            </Drawer>
        </div>
    )
}