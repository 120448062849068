import React, {useEffect,useState} from 'react'
import { Table,Button,Modal,message,Progress,Card,Input  } from 'antd';
import {analyses,delAnalysis,runAnalysis} from '../../api/analysis'
import { ExclamationCircleOutlined } from '@ant-design/icons';
import AddAnalysis from './components/addAnalysis'
import ChartPage from './components/chart'
const { Column } = Table;
const { confirm } = Modal;
const { Search } = Input;
export default function Analysis(props) {
    const [lists,setLists] = useState([]);
    const [infolists,setInfolists] = useState([]);
    const [visible,setvisible] = useState(false);
    const [plotyShow,setplotyShow] = useState(false);
    const [plotyDetail,setPlotyDetail] = useState({});
    const query= ()=>{
        let data = {user:JSON.parse(sessionStorage.getItem('userInfo')).username}
        analyses(data).then(res=>{
            let arr = res.map(item=>{
                item.key = item.id;
                return item
            })
            setLists(arr);
            setInfolists(arr);
        })
    };
    const cancel= ()=>{
        setvisible(false);
        setplotyShow(false);
    };
    useEffect(()=>{
        query()
    },[]);
    return  <div>
                <Card>
                    <span>分析列表</span>
                    <Button type="primary" style={{float:'right',padding:'4px 16px'}} onClick={()=>{
                        setvisible(true)
                    }}>新增分析</Button>
                    <Search
                        allowClear={true}
                        placeholder="分析编号/样品索引"
                        onSearch={value =>{
                            let arr = infolists.filter(item=>{
                                if((item.analysisId+'').search(value)!==-1||(item.sampleId+'').search(value)!==-1) return item;
                            })
                            setLists(!!arr?arr:[]);
                        }}
                        style={{ float:'right',width: 220,marginRight:'10px' }}
                    />
                </Card>
                <Table
                    pagination={{pageSize:10}}
                    dataSource={lists}
                >
                    <Column title="分析编号" dataIndex="analysisId" key="analysisId" align="center" render={(text,row)=>(
                       !!row.result?
                           <Button type="link" onClick={()=>{
                            setplotyShow(true);
                            setPlotyDetail(row);
                        }}>{text}</Button>
                           :<Button type="link" onClick={()=>{
                           message.warning('报告还在生成中...');
                       }}>{text}</Button>

                    )}/>
                    <Column title="样品索引" dataIndex="sampleId" key="sampleId" align="center"/>
                    <Column title="分析状态" dataIndex="status" key="status" align="center" render={(test,row)=>(
                        <span>
                            {test===0?<Progress  strokeColor={{'0%': '#ccc','100%': '#ccc',}}  percent={100} showInfo={false} size="big" />:''}
                            {test===1?<Progress  strokeColor={{'0%': '#108ee9','25%': '#87d068',}}  percent={25} showInfo={false} size="big" />:''}
                            {test===2?<Progress  strokeColor={{'0%': '#108ee9','50%': '#87d068',}}  percent={50} showInfo={false} size="big" />:''}
                            {test===3?<Progress  strokeColor={{'0%': '#108ee9','75%': '#87d068',}}  percent={75} showInfo={false} size="big" />:''}
                            {test===4?<Progress  strokeColor={{'0%': '#108ee9','100%': '#87d068',}} showInfo={false} percent={100} size="big" />:''}
                            {test===5?<Progress  strokeColor={{'0%': '#ccc','100%': '#ccc',}}  percent={100} showInfo={false} size="big" />:''}
                            {test===6?<Progress  strokeColor={{'0%': '#0b88ff','25%': '#cc1b30',}}  percent={25} showInfo={false} size="big" />:''}
                            {test===7?<Progress  strokeColor={{'0%': '#0b88ff','50%': '#cc1b30',}}  percent={50} showInfo={false} size="big" />:''}
                            {test===8?<Progress  strokeColor={{'0%': '#0b88ff','75%': '#cc1b30',}}  percent={70} showInfo={false} size="big" />:''}
                            {test===9?<Progress  strokeColor={{'0%': '#0b88ff','100%': '#cc1b30',}}  percent={100} showInfo={false} size="big" />:''}
                            状态：{
                               test===0?'分析等待开始':test===1?'质量控制完成':test===2?'基因组比对完成':test===3?'变异检测完成':test===4?'全部分析完成':test===5?'数据出错':test===6?'质量控制出错':test===7?'基因组比对出错':test===8?'变异检测出错':'报告生成出错'
                             }
                            </span>
                    ) }
                    />
                    <Column  fixed={'right'} width={120} title="操作" key="action" align="center" render={(text, row) => (
                        <span>
                                    <Button style={{marginRight:'5px'}} type="link" onClick={()=>{
                                        runAnalysis({analysisId:text.analysisId}).then(res=>{
                                            message.success('运行成功！');
                                            query()
                                        })
                                    }}>重新分析</Button>
                                    <Button type="link" danger onClick={()=>{
                                        confirm({
                                            title: '操作警告！！！',
                                            icon: <ExclamationCircleOutlined />,
                                            content: '是否删除该条分析数据！！！',
                                            okText: '确定',
                                            cancelType: 'danger',
                                            cancelText: '取消',
                                            onOk() {
                                                delAnalysis(text.analysisId).then(res=>{
                                                    message.success('删除成功！');
                                                    query()
                                                })
                                            },
                                            onCancel() {
                                                console.log('Cancel');
                                            },
                                        });
                                    }}>删除</Button>
                                </span>
                    )}/>
                </Table>
               {visible?<AddAnalysis query={query}  cancel={cancel} visible={visible}/>:''}
               {plotyShow?<ChartPage query={query}  cancel={cancel} visible={plotyShow} plotyDetail={plotyDetail}/>:''}
      </div>
}
