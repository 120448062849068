import React, { useState,useEffect } from 'react';
import { Modal,message,Input,Button  } from 'antd';
import {userUsername} from '../../../api/user'
export default function EditUserInfo(props){
    const [objData,setObjdata] = useState({});
    const [ispsd,setispsd] = useState(false);
    const okModal=()=>{
        if(!/^1\d{10}$/.test(objData.phone)) return message.error('手机号码不正确');
        userUsername(objData).then(res=>{
            message.success('修改信息成功');
            props.visibiliShow();
            props.query()
        })

    }
    useEffect(()=>{
        setObjdata(props.objData);
    },[])
    const hideModal=()=>{
        props.visibiliShow();
    }
    const data = (e) =>{
        setObjdata(Object.assign(objData,e));
    }
    return (
        <Modal
            title="用户信息"
            visible={props.visible}
            onOk={okModal}
            onCancel={hideModal}
            okText="保存"
            cancelText="取消"
        >
              <div>
                  <span>用户名: </span>
                  <p>{objData.username}</p>
              </div>
              <div>
                  <span>邮箱:</span>
                  <Input defaultValue={objData.email} onChange={(e)=>{
                      data({'email':e.target.value});
                  }}/>
              </div>
              <div>
                  <span>电话:</span>
                  <Input defaultValue={objData.phone} maxLength={11} onChange={(e)=>{
                      data({'phone':e.target.value});
                  }}/>
              </div>
              <div>
                  <span>地址:</span>
                  <Input defaultValue={objData.address} maxLength={64} onChange={(e)=>{
                      data({'address':e.target.value});
                  }}/>
              </div>
              <div>
                  <span>网址:</span>
                  <Input defaultValue={objData.website} onChange={(e)=>{
                      data({'website':e.target.value});
                  }}/>
              </div>
              <div>
                  <span>邮编:</span>
                  <Input defaultValue={objData.postal} maxLength={6} onChange={(e)=>{
                      data({'postal':e.target.value});
                  }}/>
              </div>
              <div>
                  <span>单位名称:</span>
                  <Input defaultValue={objData.office} maxLength={64} onChange={(e)=>{
                      data({'office':e.target.value});
                  }}/>
              </div>
            {
                ispsd?<div>
                    <span>新密码</span>
                    <Input maxLength={16} onChange={(e)=>{
                        data({'password':e.target.value});
                    }}/>
                </div>:''
            }
            <div style={{padding:'10px 0'}}>
                <Button type="primary" danger onClick={()=>{
                    if(!ispsd){
                        setispsd(true)
                    }else{
                        if(objData.password.length<6) return message.error('密码的长度不能少于6位');
                        userUsername(objData).then(res=>{
                            message.success('密码修改成功');
                            sessionStorage.removeItem('userInfo');
                            props.props.history.push('/');
                        })
                        setispsd(false)
                    }

                }}>{!ispsd?'修改密码':'确定修改'}</Button>
            </div>
        </Modal>
    )
}