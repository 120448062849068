import React, { useState, useEffect } from 'react';
import { Table,Button,Modal,message,Card  } from 'antd';
import UserInfo from './components/userInfo'
import EditUserInfo from './components/editUserInfo'
import {users,samples} from '../../api/user'
import { ExclamationCircleOutlined } from '@ant-design/icons';
const { Column } = Table;
const { confirm } = Modal;
function Setting(props) {
    const [list, setList] = useState([]);
    const [objData, setobjData] = useState({
        address: "",
        createdDate: "",
        email: "",
        id: "",
        key: "",
        office: "",
        phone: "",
        postal: "",
        priority: 1,
        username: "",
        website: "",
        _id: "",
    });
    const [visible, setvisible] = useState(false); //新增的显示
    const [editVisible, seteditVisible] = useState(false); // 编辑的显示
    const query = ()=> {
        users().then(res=>{
            let arr = res.map(item=>{
                item.key = item.id;
                return item
            })
            setList(arr);
        })
    }
    const visibiliShow = ()=> {
        setvisible(false);
        seteditVisible(false);
    }
    useEffect(()=>{
        query()
    },[])

    return (
        <div>
            <Card>
                <span>数据列表</span>
                <Button type="primary" style={{float:'right',padding:'4px 16px'}} onClick={()=>{
                    setvisible(true);
                }}>新增用户</Button>
            </Card>
            <Table
                pagination={{pageSize:10}}
                dataSource={list}
            >
                <Column title="用户名称" dataIndex="username" key="username" align="center"/>
                <Column title="邮箱" dataIndex="email" key="email" align="center"/>
                <Column title="手机号码" dataIndex="phone" key="phone" align="center" />
                <Column title="操作" key="action" width={195} align="center" render={(text, row) => (
                    <span>
                        <Button type="link" onClick={()=>{
                            setobjData(text);
                            seteditVisible(true);
                        }}>编辑</Button>
                        <Button type="link" danger onClick={()=>{
                            confirm({
                                title: '操作警告！！！',
                                icon: <ExclamationCircleOutlined />,
                                content: '是否删除该用户！！！',
                                okText: '确定',
                                cancelType: 'danger',
                                cancelText: '取消',
                                onOk() {
                                    samples(text.id).then(res=>{
                                            message.success('删除成功！');
                                            query()
                                    })
                                },
                                onCancel() {
                                    console.log('Cancel');
                                },
                            });
                        }}>删除</Button>
                    </span>
                )}/>
            </Table>
            {
                visible?<UserInfo visible={visible} visibiliShow={visibiliShow} query={query}/>:''
            }
            {
                editVisible?<EditUserInfo props={props} visible={editVisible} objData={objData} visibiliShow={visibiliShow} query={query}/>:''
            }
        </div>
    );

}


export default Setting