import React, { useState,useEffect } from 'react';
import {message,Input,Button,Card  } from 'antd';
import {userUsername} from '../../api/user'
import './setting.less'
export default function HomeSetting(props){
    const [objData,setObjdata] = useState(JSON.parse(sessionStorage.getItem('userInfo')));
    const [ispsd,setispsd] = useState(false);

    const data = (e) =>{
        setObjdata(Object.assign(objData,e));
    }
    return (
        <div>
            <Card>
                <span>设置</span>
                <Button type="primary"style={{float:'right',padding:'4px 16px'}} onClick={()=>{
                    if(!/^1\d{10}$/.test(objData.phone)) return message.error('手机号码不正确');
                    userUsername(objData).then(res=>{
                        message.success('修改信息成功');
                    })
                }}> 保存</Button>
            </Card>

            <div className="setting_box">

                <div>
                    <span>用户名: </span>
                    <p>{objData.username}</p>
                </div>
                <div>
                    <span>邮箱:</span>
                    <Input defaultValue={objData.email} placeholder='请输入邮箱' onChange={(e)=>{
                        data({'email':e.target.value});
                    }}/>
                </div>
                <div>
                    <span>电话:</span>
                    <Input defaultValue={objData.phone} type='number' placeholder='请输入电话' maxLength={11} onChange={(e)=>{
                        data({'phone':e.target.value});
                    }}/>
                </div>
                <div>
                    <span>地址:</span>
                    <Input defaultValue={objData.address} placeholder='请输入地址' maxLength={64} onChange={(e)=>{
                        data({'address':e.target.value});
                    }}/>
                </div>
                <div>
                    <span>网址:</span>
                    <Input defaultValue={objData.website} placeholder='请输入网址' onChange={(e)=>{
                        data({'website':e.target.value});
                    }}/>
                </div>
                <div>
                    <span>邮编:</span>
                    <Input defaultValue={objData.postal} type='number' placeholder='请输入邮编' maxLength={6} onChange={(e)=>{
                        data({'postal':e.target.value});
                    }}/>
                </div>
                <div>
                    <span>单位名称:</span>
                    <Input defaultValue={objData.office} placeholder='请输入单位名称' maxLength={64} onChange={(e)=>{
                        data({'office':e.target.value});
                    }}/>
                </div>
                {
                    ispsd?<div>
                        <span>新密码</span>
                        <Input maxLength={16} onChange={(e)=>{
                            data({'password':e.target.value});
                        }}/>
                    </div>:''
                }
                <div style={{padding:'10px 0'}}>
                    <Button type="primary" style={{padding:'4px 16px'}} danger onClick={()=>{
                        if(!ispsd){
                            setispsd(true)
                        }else{
                            if(objData.password.length<6) return message.error('密码的长度不能少于6位');
                            userUsername(objData).then(res=>{
                                message.success('密码修改成功');
                                sessionStorage.removeItem('userInfo');
                                props.history.push('/');
                            })
                            setispsd(false)
                        }

                    }}>{!ispsd?'修改密码':'确定修改'}</Button>
                </div>
            </div>
        </div>
    )
}