import React, {useEffect,useState} from 'react'
import { Table,Button,Modal,message,Card,Input,Select,DatePicker  } from 'antd';
import {samples,delSamples} from '../../api/home'
import { ExclamationCircleOutlined } from '@ant-design/icons';
import 'moment/locale/zh-cn';
import locale from 'antd/es/date-picker/locale/zh_CN';
import moment from 'moment'
import Detail from './components/detail'
import Add from './components/add'
const { Column } = Table;
const { confirm } = Modal;
const { Search } = Input;
const { Option } = Select;
const { RangePicker } = DatePicker;
 export default function Home(props) {
     const [lists,setLists] = useState([]);
     const [resh,setresh] = useState(false);
     const [infolists,setInfolists] = useState([]);
     const [sampleid,setsampleid] = useState('');
     const [visible,setvisible] = useState(false);
     const [detailValue,setdetaildetailValue] = useState('');
     const [addVisible,setaddVisible] = useState(false); //新增样品
     const query= ()=>{
         let data = {user:JSON.parse(sessionStorage.getItem('userInfo')).username}
         samples(data).then(res=>{
             let arr = res.map(item=>{
                 item.key = item.id;
                 return item
             })
             setLists(arr);
             setInfolists(arr);
         })
     }
     const cancel= ()=>{
         setsampleid('');
         setdetaildetailValue('');
         setvisible(false);
         setaddVisible(false);
     }
     useEffect(()=>{
         if(!!resh){
             setTimeout(()=>{
                 setresh(false);
             },100)
         }

     },[resh]);
     useEffect(()=>{
         query()
     },[]);
         return  <div>
             <Card>
                 <span>样本列表</span>
                 <Button style={{float:'right',padding:'4px 16px'}} type="primary" onClick={()=>{
                     setaddVisible(true);
                 }}>新增样品</Button>
                 <Search
                     allowClear={true}
                     placeholder="样品编号/受检者姓名"
                     onSearch={value =>{
                         let arr = infolists.filter(item=>{
                             if((item.sampleId+'').search(value)!==-1||(item.patientName+'').search(value)!==-1) return item;
                         })
                         setLists(!!arr?arr:[]);
                     }}
                     style={{ float:'right',width: 220,marginRight:'10px' }}
                 />
                 <Select defaultValue="时间降序" style={{ float:'right',width: 120,marginRight:'10px'  }} onChange={(e)=>{
                     if(e==1){
                          infolists.sort(function (a,b) {
                             return new Date(a.dateOfReceive).getTime()<new Date(b.dateOfReceive).getTime()?1:-1
                         })
                         setLists([...infolists]);
                         setresh(true);
                     }else{
                         infolists.sort(function (a,b) {
                             return new Date(a.dateOfReceive).getTime()>new Date(b.dateOfReceive).getTime()?1:-1
                         })
                         setLists([...infolists]);
                         setresh(true);
                     }
                 }}>
                     <Option value="1">时间降序</Option>
                     <Option value="2">时间升序</Option>
                 </Select>
                 <RangePicker locale={locale} style={{ float:'right',width: 220,marginRight:'10px' }} onChange={(e)=>{
                     console.log(e," locale={locale}")
                     if(!e){
                         setLists([...infolists]);
                         setresh(true);
                     }else {
                         let start = new Date(e[0]._d).getTime();
                         let end = new Date(e[1]._d).getTime(),arr=[];
                         infolists.forEach((item,index)=>{
                             item.time = new Date(item.dateOfReceive).getTime();
                             if(item.time>=start&&item.time<=end){
                                 arr.push(item)
                             }
                         })
                         setLists([...arr]);
                         setresh(true);
                     }
                 }

                 }/>
             </Card>
              <Table
                 pagination={{pageSize:10}}
                 dataSource={lists}
                 scroll={{ x: 1500,}}
             >
                 <Column  width={120} fixed={'left'} title="样本编号" dataIndex="sampleId" key="sampleId" align="center" render={(text,row)=>(
                     <Button type="link" onClick={()=>{
                         setsampleid(row.id);
                         setvisible(true);
                     }}>{text}</Button>
                 )}/>
                 <Column width={200} ellipsis={true} title="档案名称" dataIndex="filename1" key="filename1" align="center" render={(text,row)=>(
                    <span>{text.split('/').pop()}</span>
                 )}/>
                 <Column  width={120} title="受检者姓名" dataIndex="patientName" key="patientName" align="center" />
                 <Column  width={60} title="年龄" dataIndex="patientAge" key="patientAge" align="center" />
                 <Column   width={60}title="性别" dataIndex="patientGender" key="patientGender" align="center" />
                 <Column  width={100} title="送检医院" dataIndex="hospital" key="hospital" align="center" />
                 <Column  width={100} title="送检医生" dataIndex="doctor" key="doctor" align="center" />
                 <Column   width={100}title="门诊号/住院号" dataIndex="clinicNumber" key="clinicNumber" align="center" />
                 <Column   width={80}title="样本类型" dataIndex="sampleType" key="sampleType" align="center" />
                 <Column  width={120} title="接收日期" dataIndex="dateOfReceive" key="dateOfReceive" align="center" render={(_d)=>(
                     <span>{moment(_d).format('YYYY/MM/DD')}</span>
                 )} />
                 <Column  width={60} title="检验类型" dataIndex="sampleClass" key="sampleClass" align="center" />
                 <Column  width={100}  fixed={'right'} title="操作" key="action" align="center" render={(text, row) => (
                     <span>
                            <Button style={{marginRight:'5px'}} type="link" onClick={()=>{
                                setaddVisible(true);
                                setdetaildetailValue(text);
                                console.log(text,"setdetaildetailValue")
                            }}>编辑</Button>
                            <Button type="link" danger onClick={()=>{
                                confirm({
                                    title: '操作警告！！！',
                                    icon: <ExclamationCircleOutlined />,
                                    content: '是否删除该条样品！！！',
                                    okText: '确定',
                                    cancelType: 'danger',
                                    cancelText: '取消',
                                    onOk() {
                                        delSamples(text.sampleId).then(res=>{
                                            message.success('删除成功！');
                                            query()
                                        })
                                    },
                                    onCancel() {
                                        console.log('Cancel');
                                    },
                                });
                            }}>删除</Button>
                        </span>
                 )}/>
             </Table>
             {visible?<Detail  cancel={cancel} visible={visible} sampleid={sampleid}/>:''}
             {addVisible?<Add  query={query} detailValue={detailValue} cancel={cancel} visible={addVisible}/>:''}
         </div>
}
