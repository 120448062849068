import axios from '../assets/axios'
/*
* #搜索
---
请求地址 http://139.159.219.174/api//disease/search/?info=HEXA
* **/
export function diseaseSearch(val) {
    return axios({
        method:'get',
        url:'/disease/search/?info='+val
    })
}