import { LOGIN } from "./actionType";
import {authenticate} from '../api/login'
//包含所有的action creator
//包含所有的action creator
export const loginCreater = (data) =>({type:LOGIN,data:data})
export const login = (data)=> {
        authenticate(data).then(res=>{
            sessionStorage.setItem('userInfo',JSON.stringify(res));
            return dispatch=>{
               dispatch(loginCreater(data))
            }
        })


}