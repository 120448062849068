import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Login from '../views/login';
import router from './router'
import Error403 from '../views/403';
import Layout from '../views/component/Layout'
import App from '../App'

const Router = () =>(
    <div>
        <Switch>
            <Route path='/'
                   render={props => (
                       <App>
                           <Switch>
                               <Route path='/' exact component={Login}/>
                               <Route path='/login' exact component={Login}/>
                               <Layout props={props}>
                                   {
                                       router.map(item=>{
                                           return <Route path={item.path} exact component={item.components} key={item.name}/>
                                       })
                                   }
                               </Layout>
                               {/*路由不正确时，默认跳回登录页面*/}
                               <Route render={() => <Redirect to="/Error403" component={Error403}/>} />
                           </Switch>
                       </App>
                   )}
            />

        </Switch>
    </div>
)


export default Router;