import React,{useState,useEffect} from 'react'
import { Modal,Select,message } from 'antd';
import {samples} from '../../../api/home'
import {createAnalysis} from '../../../api/analysis'
import '../setting.less'
const { Option } = Select;
export default function AddAnalysis(props) {
    const [objData,setobjData] = useState({
        host:JSON.parse(sessionStorage.getItem('userInfo')).username,
        sampleId:'',
    });
    const [opctions,setOpctions] = useState([]);
    const query= ()=>{
        let data = {user:JSON.parse(sessionStorage.getItem('userInfo')).username}
        samples(data).then(res=>{
            let arr = res.map(item=>{
                item.key = item.id;
                return item
            })
            setOpctions(arr);
        })
    }
    useEffect(()=>{
        query();
    },[])
    return (
        <Modal
            title="样品"
            visible={props.visible}
            onOk={()=>{

                if(!objData.sampleId) return message.error('请选择样品')
                createAnalysis(objData).then(res=>{
                    message.success('样品增加成功')
                    props.cancel();
                    props.query();
                })
            }}
            onCancel={()=>{
                props.cancel();
            }}
            okText="确定"
            cancelText="取消"
        >
            <div>
                <b>选择样品: </b>
                <Select placeholder='请选择样品' style={{width:'100%'}} onChange={(e)=>{
                    setobjData(Object.assign(objData,{sampleId:e}))
                }}>
                    {opctions.map(item=>{
                        return <Option key={item.sampleId} value={item.sampleId}>{item.sampleId}</Option>
                    })}
                </Select>
            </div>

        </Modal>
    )
}