import React from 'react';
import { Layout, Menu, Breadcrumb,Button } from 'antd';
import router from '../../router/router'
import './layout.less'
import { UserOutlined, LaptopOutlined, NotificationOutlined,LogoutOutlined } from '@ant-design/icons';
const { SubMenu } = Menu;
const { Header, Content, Sider } = Layout;

export default class MainLayout extends React.Component {
    constructor() {
        super();
        this.state = {
            userInfo:{},
            menus1:[],
            menus0:[],
            selectInde:sessionStorage.getItem('selectInde')?sessionStorage.getItem('selectInde'):'0',
        }
    }
    componentDidMount(){
        if(!JSON.parse(sessionStorage.getItem('userInfo'))){
            this.props.props.history.push('/');
        }else{
            let menus0 = [],menus1=[];
            router.forEach(item=>{
                if(item.type===0){ menus0.push(item)}else {menus1.push(item)}
            })
           this.setState({
               userInfo:JSON.parse(sessionStorage.getItem('userInfo')),
               menus0,
               menus1
           },()=>{
                if(this.state.userInfo.priority==0){
                    this.props.props.history.push(this.state.menus0[this.state.selectInde].path);
                }else {
                    this.props.props.history.push(this.state.menus1[this.state.selectInde].path);
                }
           })
        }

    }
    render() {
        return <Layout>
            <Header className="header">
                <div className="logo">
                    <img src={require('./inner-logo.png')} alt=""/>
                </div>
                <Button className="authName" onClick={()=>{
                    this.props.props.history.push('/homeSetting');
                }} type="dashed">{this.state.userInfo.username}</Button>
                <LogoutOutlined  className="out" onClick={()=>{
                    sessionStorage.removeItem('userInfo');
                    sessionStorage.removeItem('selectInde');
                    this.props.props.history.push('/');
                }}/>
            </Header>
            <Layout className='layout_box'>
                <Sider width={200} className="site-layout-background layout_box_left">
                    <Menu
                        mode="inline"
                        defaultSelectedKeys={[this.state.selectInde]}
                        defaultOpenKeys={['sub1']}
                        style={{ height: '100%', borderRight: 0 }}
                    >
                        {
                            this.state.userInfo.priority==0?this.state.menus0.map((item,index)=>{
                                return (

                                      item.isShow&& <Menu.Item key={index} onClick={()=>{
                                        this.props.props.history.push(item.path);
                                        this.setState({
                                            selectInde:index
                                        },()=>{
                                            sessionStorage.setItem('selectInde',this.state.selectInde)
                                        })
                                    }}>{item.name}</Menu.Item>
                                )

                            }):this.state.menus1.map((item,index)=>{
                                return  <Menu.Item key={index} onClick={()=>{
                                    this.props.props.history.push(item.path)
                                    this.setState({
                                        selectInde:index
                                    },()=>{
                                        sessionStorage.setItem('selectInde',this.state.selectInde)
                                    })

                                }}>{item.name}</Menu.Item>
                            })
                        }

                    </Menu>
                </Sider>
                <Layout className='layout_box_right' style={{ padding: '0 24px 24px' }}>
                    <Content
                        className="site-layout-background"
                        style={{
                            padding: 24,
                            margin: 0,
                            minHeight: 280,
                        }}
                    >
                        {this.props.children}
                    </Content>
                </Layout>
            </Layout>
        </Layout>
    }
}