import React,{useState,useEffect} from 'react'
import { Modal,DatePicker,Input,Upload,Button,Progress,message } from 'antd';
import moment from 'moment';
import {samplesupload,samplesupData} from '../../../api/home'
import '../setting.less'
export default function Detail(props) {
    const [objData,setobjData] = useState({});
	const [state,setState] = useState({});
	const configs = {
		onUploadProgress: (progress) => {
			let { loaded } = progress
			let { fileSize } = state
			let baifenbi = (loaded / fileSize * 100).toFixed(2)
			setState({
				baifenbi,
				uploading:true
			})
		}
	}
	useEffect(()=>{
		setState({
			uploading: false,
			fileSize: 0,
			baifenbi: 0
		})
	},[])
    useEffect(()=>{
        if(!!props.detailValue){
                let obj = {
                 ...props.detailValue,
                   wenjian2:props.detailValue.filename2?props.detailValue.filename2.split('/')[props.detailValue.filename2.split('/').length-1]:'',
                   wenjian1:props.detailValue.filename1?props.detailValue.filename1.split('/')[props.detailValue.filename1.split('/').length-1]:'',
            }
            setobjData(obj)
        }else{
            setobjData({
                "filename1":"", //档案名称1
                "filename2":"", //档案名称2
                "patientName":"", //受检者姓名
                "patientAge":"", //年龄
                "patientGender":"", //性别
                "hospital":JSON.parse(sessionStorage.getItem('userInfo')).office, //送检医院
                "doctor":"", //送检医生
                "clinicNumber":"", //门诊号/住院号
                "sampleType":"", //样本类型
                "dateOfSampling":"", //采样日期
                "dateOfReceive":"", //收样日期
                "dateOfCheck":"", //检查日期
                "sampleClass":"",  //检验类型
                "file1":'',
                "file2":'',
                "host":JSON.parse(sessionStorage.getItem('userInfo')).username, //样品属主
            })
        }
    },[])
    const data = (e) =>{
        setobjData(Object.assign(objData,e));
    }
	
    return (
        <Modal
            title={!!props.detailValue?'编辑样品':'新增样品'}
            visible={props.visible}
			maskClosable={false}
            onOk={()=>{
                let formData = new FormData();
                if(!objData.patientName) return message.error('姓名不能为空');
                if(!objData.patientAge) return message.error('年龄不能为空');
                if(!objData.patientGender) return message.error('性别不能为空');
                if(!objData.hospital) return message.error('医院不能为空');
                if(!objData.doctor) return message.error('医生不能为空');
                if(!objData.clinicNumber) return message.error('病历号不能为空');
                if(!objData.sampleType) return message.error('样本类型不能为空');
                if(!objData.dateOfSampling) return message.error('抽样日期不能为空');
                if(!objData.dateOfReceive) return message.error('收样日期不能为空');
                if(!objData.dateOfCheck) return message.error('检查日期不能为空');
                if(!objData.filename1) return message.error('数据文件1不能为空');
                if(!objData.filename2) return message.error('数据文件2不能为空');
                formData.append('patientName', objData.patientName);
                formData.append('patientAge', objData.patientAge);
                formData.append('patientGender', objData.patientGender);
                formData.append('hospital', objData.hospital);
                formData.append('doctor',objData.doctor);
                formData.append('clinicNumber',objData.clinicNumber);
                formData.append('sampleType',objData.sampleType);
                formData.append('dateOfSampling',objData.dateOfSampling);
                formData.append('dateOfReceive',objData.dateOfReceive);
                formData.append('dateOfCheck',objData.dateOfCheck);
                formData.append('sampleClass','A');
                formData.append('host', objData.host);
                if(!!props.detailValue){
                    samplesupData(objData.sampleId,formData).then(res=>{
                        message.success('样品编辑成功')
                        props.cancel();
                        props.query();
                    })

                }else{
					setState({uploading:true});
                    formData.append('filename1', objData.filename1);
                    formData.append('filename2', objData.filename2);
                    formData.append('file1', objData.file1);
                    formData.append('file2', objData.file2);
                    samplesupload(formData,configs).then(res=>{
							setState({
								baifenbi:100,
							})
                        	message.success('样品新增成功')
                        	props.cancel();
                        	props.query();},
						err=>{
							message.error('样品上传失败');
							props.cancel();
							props.query();
						})
                }

            }}
            onCancel={()=>{
                props.cancel();
            }}
			
			okButtonProps={{disabled:state.uploading}}
            okText="确定"
            cancelText="取消"
        >
            <div className="li">
                <b>姓名: </b>
                <Input className='right' defaultValue={objData.patientName} placeholder='请输入姓名' onChange={(e)=>{
                    data({'patientName':e.target.value});
                }}/>
            </div>
            <div className="li">
                <b>年龄: </b>
                <Input className='right' defaultValue={objData.patientAge} placeholder='请输入年龄' onChange={(e)=>{
                    data({'patientAge':e.target.value});
                }}/>
            </div>
            <div className="li">
                <b>性别: </b>
                <Input className='right' defaultValue={objData.patientGender} placeholder='请输入性别' onChange={(e)=>{
                    data({'patientGender':e.target.value});
                }}/>
            </div>
            <div className="li">
                <b>医院: </b>
                <Input className='right' defaultValue={objData.hospital} placeholder='请输入医院' onChange={(e)=>{
                    data({'hospital':e.target.value});
                }}/>
            </div>
            <div className="li">
                <b>医生: </b>
                <Input className='right' defaultValue={objData.doctor} placeholder='请输入医生' onChange={(e)=>{
                    data({'doctor':e.target.value});
                }}/>
            </div>
            <div className="li">
                <b>病历号: </b>
                <Input className='right' defaultValue={objData.clinicNumber} placeholder='请输入病历号' onChange={(e)=>{
                    data({'clinicNumber':e.target.value});
                }}/>
            </div>
            <div className="li">
                <b>样品类型: </b>
                <Input  className='right' defaultValue={objData.sampleType} placeholder='请输入样品类型' onChange={(e)=>{
                    data({'sampleType':e.target.value});
                }}/>
            </div>
            <div className="li" style={{marginTop:'10px'}}>
                <b>抽样时间: </b>
                <DatePicker className='right' format={'YYYY/MM/DD'} style={{width:'100%'}} placeholder='请选择抽样时间' onChange={(e)=>{
                    console.log(e,"222")
                    data({'dateOfSampling':e._d});
                }} />
            </div>
            <div className="li" style={{marginTop:'10px'}}>
                <b>收样时间: </b>
                <DatePicker className='right' format={'YYYY/MM/DD'} style={{width:'100%'}} placeholder='请选择收样时间' onChange={(e)=>{
                    data({'dateOfReceive':e._d});
                }} />
            </div>

            <div className="li" style={{marginTop:'10px'}}>
                <b>检查时间: </b>
                <DatePicker  className='right' format={'YYYY/MM/DD'} style={{width:'100%'}} placeholder='请选择检查时间' onChange={(e)=>{
                    data({'dateOfCheck':e._d});
                }} />
            </div>
            <div style={{marginTop:'10px'}}>
                <b>数据文件1: </b>

                {!!props.detailValue?<b>{objData.wenjian1}</b>:<input type="file" accept=".gz,.fq,.fastq,.js" onChange={(e)=>{
                    let files = e.target.files[0];
                    data({'filename1':files.name});
                    data({'file1':files});
					setState({
						fileSize: files.size,
						baifenbi: 0
					});
                }} />}

            </div>
            <div style={{marginTop:'10px'}}>
                <b>数据文件2: </b>
                {!!props.detailValue?<b>{objData.wenjian2}</b>:<input type="file" accept=".gz,.fq,.fastq,.js" onChange={(e)=>{
                    let files = e.target.files[0];
                    data({'filename2':files.name});
                    data({'file2':files});
					setState({
						fileSize: state.fileSize + files.size,
						baifenbi: 0
					});
                }}/>}
            </div>
			<div>
				{!props.detailValue?<Progress style={{ marginTop: 20}} status={state.baifenbi == 100 ? 'success' : ''} percent={state.baifenbi}></Progress>:''}
			</div>
        </Modal>
    )
}
