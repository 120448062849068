import React,{useState,useEffect} from 'react'
import { Modal  } from 'antd';
import {samplesdetail} from '../../../api/home'
export default function Detail(props) {
    const [objData,setobjData] = useState({});
    useEffect(()=>{
        samplesdetail(props.sampleid).then(res=>{
            res.filename1=res.filename1?res.filename1.split('/')[res.filename1.split('/').length-1]:res.filename1;
            res.filename2=res.filename2?res.filename2.split('/')[res.filename2.split('/').length-1]:res.filename2;
            setobjData(res);
        })

    },[])
    return (
        <Modal
            title="样品详情"
            visible={props.visible}
            onOk={()=>{
                props.cancel();
            }}
            onCancel={()=>{
                props.cancel();
            }}
            okText="确定"
            cancelText="取消"
        >
            <div>
                <b>姓名: </b>
                 <span>{objData.patientName}</span>
            </div>
            <div>
                <b>年龄: </b>
                <span>{objData.patientAge}</span>
            </div>
            <div>
                <b>性别: </b>
                <span>{objData.patientGender}</span>
            </div>
            <div>
                <b>医院: </b>
                <span>{objData.hospital}</span>
            </div>
            <div>
                <b>医生: </b>
                <span>{objData.doctor}</span>
            </div>
            <div>
                <b>病历号: </b>
                <span>{objData.clinicNumber}</span>
            </div>
            <div>
                <b>样品类型: </b>
                <span>{objData.sampleType}</span>
            </div>
            <div>
                <b>抽样时间: </b>
                <span>{objData.dateOfSampling}</span>
            </div>
            <div>
                <b>收样时间: </b>
                <span>{objData.dateOfReceive}</span>
            </div>
            <div>
                <b>检查时间: </b>
                <span>{objData.dateOfCheck}</span>
            </div>
            <div>
                <b>数据文件1: </b>
                <span>{objData.filename1}</span>
            </div>
            <div>
                <b>数据文件2: </b>
                <span>{objData.filename2}</span>
            </div>
        </Modal>
    )
}