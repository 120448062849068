import axios from '../assets/axios'
/*
* #样品列表
---
请求地址 http://139.159.219.174/api/samples
* **/
export function samples(params) {
    return axios({
        method:'get',
        url:'/samples',
        params
    })
}
/*
* #样品删除
---
请求地址 http://139.159.219.174/api/samples
* **/
export function delSamples(id) {
    return axios({
        method:'delete',
        url:'/samples/'+id,
    })
}
/*
* #样品详情
---
请求地址 http://139.159.219.174/api/samples
* **/
export function samplesdetail(id) {
    return axios({
        method:'get',
        url:'/samples/'+id,
    })
}
/*
* #样品详情
---
请求地址 http://139.159.219.174/api/samples/upload
* **/
export function samplesupload(data,config) {
    return axios({
		...config,
        method:'post',
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        url:'/samples/upload',
        data
    })
}
/*
* #样品更新
---
请求地址 this.http.put(`${config.apiUrl}/samples/${sampleId}`,propList);
* **/
export function samplesupData(id,data) {
    return axios({
        method:'put',
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        url:'/samples/'+id,
        data
    })
}
