import Home from '../views/home'
import Analysis from '../views/home/analysis'
import Seach from '../views/search/seach'
import HomeSetting from '../views/home/setting'
import Setting from '../views/setting'

 const router = [
    {
        name:'样品列表',
        components:Home,
        type:0,
        isShow:true,
        path:'/home'
    },
    {
        name:'分析列表',
        components:Analysis,
        type:0,
        isShow:true,
        path:'/analysis'
    },
     {
         name:'疾病搜索',
         components:Seach,
         type:0,
         isShow:true,
         path:'/seach'
     },
    {
        name:'设置',
        components:HomeSetting,
        type:0,
        isShow:false,
        path:'/homeSetting'
    },
    {
        name:'用户管理',
        components:Setting,
        type:1,
        isShow:true,
        path:'/userMrg'
    }
];
export default router;