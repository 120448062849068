import React,{useState,useEffect,useRef} from 'react';
import { Button,Modal,message,Tabs,Row,Col,Table  } from 'antd';
import moment from 'moment'
import {snps,disease,reportsdownload} from '../../../api/analysis'
import {samples} from '../../../api/home'
import ReactEcharts from 'echarts-for-react';
import Url from '../../../assets/config';
import '../setting.less'
const { Column } = Table;
const { TabPane } = Tabs;
export default function ChartPage(props) {
    console.log(props,"props")
    const [objData,setobjData] = useState({disease:{},snps:{}});
    const [samplesObj,setsamplesObj] = useState({disease:{},snps:{}});
    const [diseaseObj,setdiseaseObj] = useState(null);
    const [snpsObj,setsnpsObj] = useState({});
    const [option,setoption] = useState({}); //图例1
    const [option1,setoption1] = useState({}); // 图例2
    const [option2,setoption2] = useState({});// 图例3
    const [option3,setoption3] = useState({});// 图例3
    const containerRef = useRef(null);
    const callback =(e)=>{
       console.log(e,"eeee")

    };
    useEffect(()=>{
        // setobjData(props.plotyDetail);
            let qualX = Object.keys(props.plotyDetail.result.qualityControl.qual);
           let qualY = Object.values(props.plotyDetail.result.qualityControl.qual).map(item=>{
               return item[3];
           })
        setoption(
            {
                title: {
                    text: 'base quality distribution'
                },
                backgroundColor: '#fff',
                legend: {
                    data: ['quality',],
                    left: 10
                },
                // brush: {
                //     toolbox: ['rect', 'polygon', 'lineX', 'lineY', 'keep', 'clear'],
                //     xAxisIndex: 0
                // },
                toolbox: {
                    feature: {
                        magicType: {
                            type: ['stack', 'tiled']
                        },
                        dataView: {},
                        saveAsImage: {}
                    }
                },
                tooltip: {},
                xAxis: {
                    data: qualX,
                    // name: 'quality',
                    axisLine: {onZero: true},
                    splitLine: {show: false},
                    splitArea: {show: false}
                },
                yAxis: {
                    inverse: true,
                    splitArea: {show: false}
                },
                grid: {
                    left: 100
                },
                series: [
                    {
                        // name: 'quality',
                        type: 'bar',
                        stack: 'one',
                        data: qualY
                    },
                ]
            }
        )
    },[]);
    useEffect(()=>{

       setoption1(
           {
               title: {
                   text: 'GC distribution'
               },
               tooltip: {
                   trigger: 'none',
                   axisPointer: {
                       type: 'cross'
                   }
               },
               grid: {
                   top: 70,
                   bottom: 50
               },
               toolbox: {
                   feature: {
                       saveAsImage: {}
                   }
               },
               xAxis: [
                   {
                       type: 'category',
                       axisTick: {
                           alignWithLabel: true
                       },
                       axisLine: {
                           onZero: false,
                       },
                       axisPointer: {
                           label: {
                               formatter: function (params) {
                                   return 'GC  ' + params.value
                                       + (params.seriesData.length ? '：' + params.seriesData[0].data : '');
                               }
                           }
                       },
                       data:props.plotyDetail.result.qualityControl.gc['GC Content']
                   },
                   {
                       type: 'category',

                   }
               ],
               yAxis: [
                   {
                       type: 'value'
                   }
               ],
               series: [
                   {
                       name: 'GC distribution',
                       type: 'line',
                       data: props.plotyDetail.result.qualityControl.gc['Count']
                   },

               ]
           }
       )
    },[]);
    useEffect(()=>{
        setoption2(
            {
                title: {
                    text: 'base content distribution'
                },
                tooltip: {
                    trigger: 'axis'
                },
                legend: {
                    data: ['A', 'C', 'G', 'T']
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                toolbox: {
                    feature: {
                        saveAsImage: {}
                    }
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: props.plotyDetail.result.qualityControl.atcg.Base
                },
                yAxis: {
                    type: 'value'
                },
                series: [
                    {
                        name: 'A',
                        type: 'line',
                        data: props.plotyDetail.result.qualityControl.atcg.A
                    },
                    {
                        name: 'C',
                        type: 'line',
                        data: props.plotyDetail.result.qualityControl.atcg.C
                    },
                    {
                        name: 'G',
                        type: 'line',
                        data: props.plotyDetail.result.qualityControl.atcg.G
                    },
                    {
                        name: 'T',
                        type: 'line',
                        data: props.plotyDetail.result.qualityControl.atcg.T
                    }
                ]
            }
        )
    },[]);
    useEffect(()=>{
        setoption3(
            {
                title: {
                    text: 'adpter distribution'
                },
                tooltip: {
                    trigger: 'none',
                    axisPointer: {
                        type: 'cross'
                    }
                },
                grid: {
                    top: 70,
                    bottom: 50
                },
                toolbox: {
                    feature: {
                        saveAsImage: {}
                    }
                },
                xAxis: [
                    {
                        type: 'category',
                        axisTick: {
                            alignWithLabel: true
                        },
                        axisLine: {
                            onZero: false,
                        },
                        axisPointer: {
                            label: {
                                formatter: function (params) {
                                    return 'GC  ' + params.value
                                        + (params.seriesData.length ? '：' + params.seriesData[0].data : '');
                                }
                            }
                        },
                        data:props.plotyDetail.result.qualityControl.adp['Position']
                    },
                    {
                        type: 'category',

                    }
                ],
                yAxis: [
                    {
                        type: 'value'
                    }
                ],
                series: [
                    {
                        name: 'adpter distribution',
                        type: 'line',
                        data: props.plotyDetail.result.qualityControl.adp['Illumina Universal Adapter']
                    },

                ]
            }
        )
    },[]);
    useEffect(()=>{
        let obj={};
		if(!!props.plotyDetail.result&&props.plotyDetail.result.targets.length>0){
        	snps(props.plotyDetail.result.targets[0][0]).then(res=>{
            	res.key = '1';
            	obj.snps = res;
            	disease(res.Manual_ID).then(res1=>{
                	obj.disease = res1;
                	setobjData(obj);
                	let arr = [];
                	for(let k in res1){
                    	arr.push([k,res1[k]])
                	}
                	setdiseaseObj(arr);

                	setsnpsObj(res);
            	})
        	})
		}
    },[])
    useEffect(()=>{

        let data = {user:JSON.parse(sessionStorage.getItem('userInfo')).username}
        samples(data).then(res=>{
             res.forEach(item=>{
                 if(item.sampleId === props.plotyDetail.sampleId){
                     console.log(item,' // setsamplesObj(arr);')
                     setsamplesObj(item);
                 }
             })
        })
    },[])
    return (
        <div>
            <Modal
                width='1000px'
                title="样品分析"
                visible={props.visible}
                onOk={()=>{
                        props.cancel();
                }}
                onCancel={()=>{
                    props.cancel();
                }}
                okText="确定"
                cancelText="取消"
            >
                <Tabs defaultActiveKey="1" onChange={callback}>
                    <TabPane tab="质量控制" key="1">
                        {/**/}
                        <div>
                            <ReactEcharts option={option}></ReactEcharts>
                        </div>
                        {/**/}
                       <div>
                           <ReactEcharts option={option1}></ReactEcharts>
                       </div>
                        {/**/}
                       <div>
                           <ReactEcharts option={option2}></ReactEcharts>
                       </div>
                        {/**/}
                        <div>
                            <ReactEcharts option={option3}></ReactEcharts>
                        </div>
                    </TabPane>
                    <TabPane tab="候选变异" key="2">
                        <div>
                            <Table dataSource={[snpsObj]} scroll={{ x: 1500,}}>
                                {snpsObj&&Object.keys(snpsObj).map((item,index)=>{
                                    return (
                                        item!='id'&&item!='_id'&&item!='key'&&<Column  width={200} ellipsis={true}
                                                 title={item=='Manual_ID'?"疾病编号":item=='Disease'?'疾病英文名':item}
                                                 dataIndex={item} key={index+'bianyi'} align="center" render={(text,row)=>(
                                            <span>{text}</span>
                                        )}></Column>
                                    )
                                })}
                            </Table>
                        </div>

                    </TabPane>
                    <TabPane tab="候选疾病" key="3">
                        {
                            diseaseObj&&diseaseObj.map((item1,index)=> {
                                return (
                                    item1[0]!='id'&&item1[0]!='_id'&&item1[0]!='key'&&<div key={item1[0]}>
                                        <Row style={{border: '1px solid #ccc', borderBottom: 0, textAlign: 'center'}}>
                                            <Col span={24} style={{fontSize:'16px',fontWeight:900,background:'#cef0fa'}}>
                                                {item1[0]==='Manual_ID'?'疾病编号':
                                                    item1[0]==='Phenotypic_ID'?'表型编号':
                                                    item1[0]==='Disease'?'疾病英文名':
                                                    item1[0]==='Disease_ch'?'疾病中文名':
                                                    item1[0]==='Gene'?'基因':
                                                    item1[0]==='DiseaseIntro'?'疾病概述':
                                                    item1[0]==='Clinic'?'临床表现':
                                                    item1[0]==='image'?'影像学表现':
                                                    item1[0]==='pathlog'?'致病机理':
                                                     item1[0]==='GeneIntro'?'致病基因概述':
                                                     item1[0]==='geneFunc'?'致病基因功能':
                                                     item1[0]==='mechanism'?'基因突变致病机理':
                                                     item1[0]==='mutations'?'基因突变综述':
                                                     item1[0]==='reference'?'参考文献':item1[0]

                                                }
                                                </Col>
                                        </Row>
                                        <Row style={{border: '1px solid #ccc', borderBottom: 0}}>
                                            <Col style={{padding:'10px'}} span={24}>{item1[1]}</Col>
                                        </Row>
                                    </div>

                                )
                              })
                        }

                    </TabPane>
                    <TabPane tab="报告" key="4">
                        <div className="gird">
                            <p className="gird_p"> <Button type='primary' size='small' onClick={()=>{
                                let url1 = Url+'reports/download/?id='+props.plotyDetail.analysisId;
                                let xhr = new XMLHttpRequest();
                                //GET请求,请求路径url,async(是否异步)
                                xhr.open('GET', url1, true);
                                //设置请求头参数的方式,如果没有可忽略此行代码
                                xhr.setRequestHeader("Authorization",'Bearer '+JSON.parse(sessionStorage.getItem('userInfo')).token);
                                //设置响应类型为 blob
                                xhr.responseType = 'blob';
                                //关键部分
                                xhr.onload = function (e) {
                                    //如果请求执行成功
                                    if (this.status == 200) {
                                        var blob = this.response;
                                        var filename = props.plotyDetail.analysisId+".docx";//如123.xls

                                        var reader = new FileReader();
                                        reader.readAsDataURL(blob); // 转换为base64，可以直接放入a表情href
                                        reader.onload =function (e) {
                                            var a = document.createElement('a');
                                            a.id = 'down'
                                            a.href = e.target.result;
                                            a.download=filename;
                                            document.querySelector('body').append(a);
                                            a.click();
                                            document.querySelector('#down').remove();
                                        }
                                    }
                                };
                                //发送请求
                                xhr.send();
                            }}>导出word</Button></p>
                            <h4>{samplesObj.patientName}检测报告</h4>
                            <Row style={{borderBottom:0}}>
                                <Col style={{borderLeft:0}} className='centent title' span={24}>样本信息</Col>
                            </Row>
                            <Row style={{borderBottom:0}}>
                                <Col style={{borderLeft:0}} className='centent' span={3}>样品编号</Col>
                                <Col className='centent' span={3}>送检医院</Col>
                                <Col className='centent' span={3}>送检医生</Col>
                                <Col className='centent' span={3}>样本类型</Col>
                                <Col className='centent' span={4}>采样日期</Col>
                                <Col className='centent' span={4}>接收日期</Col>
                                <Col className='centent' span={4}>检测日期</Col>
                            </Row>
                            <Row style={{borderBottom:0}}>
                                <Col style={{borderLeft:0}} className='centent' span={3}>{samplesObj.sampleId}</Col>
                                <Col className='centent' span={3}>{samplesObj.hospital}</Col>
                                <Col className='centent' span={3}>{samplesObj.doctor}</Col>
                                <Col className='centent' span={3}>{samplesObj.sampleType}</Col>
                                <Col className='centent' span={4}>{moment(samplesObj.dateOfSampling).format('YYYY/MM/DD')}</Col>
                                <Col className='centent' span={4}>{moment(samplesObj.dateOfReceive).format('YYYY/MM/DD')}</Col>
                                <Col className='centent' span={4}>{moment(samplesObj.dateOfCheck).format('YYYY/MM/DD')}</Col>
                            </Row>
                            {/**/}
                            <Row style={{borderBottom:0}}>
                                <Col style={{borderLeft:0}} className='centent title' span={24}>受检者信息</Col>
                            </Row>
                            <Row style={{borderBottom:0}}>
                                <Col style={{borderLeft:0}} className='centent' span={4}>受检者姓名</Col>
                                <Col className='centent' span={4}>受检者电话</Col>
                                <Col className='centent' span={7}>门诊号/住院号</Col>

                                <Col className='centent' span={3}>性别</Col>
                                <Col className='centent' span={3}>民族</Col>
                                <Col className='centent' span={3}>年龄</Col>
                            </Row>
                            <Row style={{borderBottom:0}}>
                                <Col style={{borderLeft:0}} className='centent' span={4}>{samplesObj.patientName}</Col>
                                <Col className='centent' span={4}>NA</Col>
                                <Col className='centent' span={7}>{samplesObj.clinicNumber}</Col>
                                <Col className='centent' span={3}>{samplesObj.patientGender}</Col>
                                <Col className='centent' span={3}>NA</Col>
                                <Col className='centent' span={3}>{samplesObj.patientAge}</Col>
                            </Row>
                            <Row style={{borderBottom:0}}>
                                <Col style={{borderLeft:0}} className='centent' span={4}>临床表现或家族史</Col>
                                <Col className='centent' span={20}>NA</Col>
                            </Row>
                            {/**/}
                            <Row style={{borderBottom:0}}>
                                <Col style={{borderLeft:0}}  className='centent title' span={24}>检测信息</Col>
                            </Row>
                            <Row style={{borderBottom:0}}>
                                <Col style={{borderLeft:0}}  className='centent' span={4}>检测项目名称</Col>
                                <Col className='centent' span={20}>神经系统遗传病检测panel</Col>
                            </Row>
                            <Row style={{borderBottom:0}}>
                                <Col style={{borderLeft:0}}  className='centent' span={4}>疾病内容</Col>
                                <Col className='centent' span={20}>500种神经系统单基因遗传病</Col>
                            </Row>
                            <Row style={{borderBottom:0}}>
                                <Col style={{borderLeft:0}}  className='centent' span={4}>检测基因</Col>
                                <Col className='centent' span={20}>500个基因</Col>
                            </Row>
                            <Row style={{borderBottom:0}}>
                                <Col style={{borderLeft:0}}  className='centent' span={4}>检测方法</Col>
                                <Col className='centent' span={20}>二代测序</Col>
                            </Row>
                            <Row style={{borderBottom:0}}>
                                <Col style={{borderLeft:0}}  className='centent' span={4}>检测目的</Col>
                                <Col className='centent' span={20}>寻找已知的神经系统单基因遗传致病突变</Col>
                            </Row>
                            {/**/}
                            <Row style={{borderBottom:0}}>
                                <Col style={{borderLeft:0}}  className='centent title' span={24}>检测结论</Col>
                            </Row>
                            <Row style={{borderBottom:0}}>
                                <Col style={{borderLeft:0}}  className='centent' span={24}>检测到{props.plotyDetail.result.targets&&props.plotyDetail.result.targets.length>0?props.plotyDetail.result.targets.length:0}个候选致病突变，具体突变见“主要检测结果</Col>
                            </Row>
                            <Row style={{borderBottom:0}}>
                                <Col style={{borderLeft:0}}  className='centent title' span={24}>主要检测结果</Col>
                            </Row>
                            <Row style={{borderBottom:0}}>
                                <Col style={{borderLeft:0}} className='centent' span={2}>基因</Col>
                                <Col className='centent' span={3}>染色体位置</Col>
                                <Col className='centent' span={3}>参考序列</Col>
                                <Col className='centent' span={6}>核酸改变/氨基酸改变</Col>
                                <Col className='centent' span={2}>基因型</Col>
                                {/*<Col className='centent' span={2}>致病性分类</Col>*/}
                                <Col className='centent' span={4}>相关疾病</Col>
                                <Col className='centent' span={2}>遗传模式</Col>
                            </Row>
							{!!props.plotyDetail.result.targets.length>0?
                            <Row style={{borderBottom:0}}>
                                <Col style={{borderLeft:0}} className='centent' span={2}>{objData.snps.Gene}</Col>
                                <Col className='centent' span={3}>chr{objData.snps.Chr}:{objData.snps.Start}</Col>
                                <Col className='centent' span={3}>{objData.snps.Ref}</Col>
                                <Col className='centent' span={6}>{objData.snps.AAChange_refGene}</Col>
                                <Col className='centent' span={2}>{props.plotyDetail.result.targets[0][1]}</Col>
                                {/*<Col className='centent' span={2}>{}</Col>*/}
                                <Col className='centent' span={4}>{objData.disease.Disease_ch}</Col>
                                <Col className='centent' span={2}>NA</Col>
                            </Row>
							:''
							}
                            <Row style={{borderBottom:0}}>
                                <Col style={{borderLeft:0}}  className='centent title' span={24}>疾病临床信息</Col>
                            </Row>
							{!!objData.disease?
                            <Row style={{borderBottom:0}}>
                                <Col style={{borderLeft:0}} className='left' span={24}>{objData.disease.Clinic}</Col>
                            </Row>:''}
                            <Row style={{borderBottom:0}}>
                                <Col style={{borderLeft:0}} className='centent' span={24}>
                                    备注：
                                </Col>
                            </Row>
                            {/**/}
                            <Row style={{borderBottom:0}}>
                                <Col style={{borderLeft:0}} className='centent' span={24}>检测方法说明及局限性</Col>
                            </Row>
                            <Row style={{borderBottom:0}}>
                                <Col style={{borderLeft:0}} className='left' span={24}>
                                    该检测项目通过二代高通量测序对被检者罹患的疑难神经系统疾病的致病基因突变或者可能携带的隐型基因突变进行筛查。检测项目特异性针对项目内的疾病和基因突变进行预警，对于不在该列表内的疾病和基因突变不能保证。对于阴性结果仅代表没有找到项目内的致病基因突变。对于阳性结果代表该基因突变是疾病发生的主要原因，或者该突变是一个隐性携带。
                                </Col>
                            </Row>

                            <Row style={{borderBottom:0}}>
                                <Col style={{borderLeft:0}} className='centent title' span={24}>参考文献</Col>
                            </Row>
                            <Row >
                                <Col style={{borderLeft:0}} className='left' span={24}>{objData.disease.reference}</Col>
                            </Row>
                        </div>
                    </TabPane>
                </Tabs>
            </Modal>
        </div>
    )
}
