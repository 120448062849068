//包含n个reducer函数的模块
import {LOGIN} from './actionType'
import {combineReducers} from 'redux'
function login(state={},action){ //形参默认值
    switch(action.type){
        case LOGIN:
            return action.data
        default:
            return state
    }
}
export default combineReducers({
    login
})