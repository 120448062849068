import React, { useState } from 'react';
import { Select,Modal,message,Input  } from 'antd';
import {register} from '../../../api/user'
const { Option } = Select;
export default function UserInfo(props){
    const [objData,setObjdata] = useState({
        "username":"",// 用户名
        "priority":"1",// 权限级别 1 管理员， 0 普通用户
        "password":"", //密码
        "confirmPassword":"" // 确认密码
});
    const okModal=()=>{
        if(!objData.username) return message.error('用户名不能为空');
        if(!objData.password) return message.error('密码不能为空');
        if(!objData.confirmPassword) return message.error('确认密码不能为空');
        if(objData.password!==objData.confirmPassword) return message.error('两次输入的密码不一致');
        register(objData).then(res=>{
            message.success('新增用户成功');
            props.visibiliShow();
            props.query()
        })

    }
    const hideModal=()=>{
        props.visibiliShow();
    }
    return (
        <Modal
            title="注册用户"
            visible={props.visible}
            onOk={okModal}
            onCancel={hideModal}
            okText="注册"
            cancelText="取消"
        >
              <div>
                  <span>用户名：</span>
                  <Input maxLength={16} placeholder='请输入用户名' onChange={(e)=>{
                       setObjdata(Object.assign(objData,{username:e.target.value}));
                  }}/>
              </div>
              <div>
                  <span>优先级：</span>
                  <Select defaultValue="1" placeholder='请选择优先级' style={{width:'100%'}}  onChange={(e)=>{
                      setObjdata(Object.assign(objData,{priority:e}));
                  }}>
                      <Option value='0'>普通用户</Option>
                      <Option value='1'>管理员</Option>
                  </Select>
              </div>
              <div>
                  <span>密码：</span>
                  <Input maxLength={32} placeholder='请输入密码' onChange={(e)=>{
                      setObjdata(Object.assign(objData,{password:e.target.value}));
                  }}/>
              </div>
              <div>
                  <span>确认密码：</span>
                  <Input maxLength={32} placeholder='请输入确认密码' onChange={(e)=>{
                      setObjdata(Object.assign(objData,{confirmPassword:e.target.value}));
                  }}/>
              </div>
        </Modal>
    )
}